import React from 'react';
import './SignIn.css';
import { Button} from '../Util/Button';
import { Link } from 'react-router-dom';





function SignIn(){
    return (
        <div className='contact-container'>

            <h1>Sign In</h1>
        </div>

    )
}

export default SignIn;